import SendNotification from "@/components/shared/SendNotification";
import ApiService from "./ApiService";
import type {
	SignInCredential,
	ForgotPassword,
	ResetPassword,
	SignInResponse,
	SignUpResponse,
	SignUpEmailCredential,
	SignUpPhoneCredential,
	VerifyCode,
	ResendOTP,
} from "@/@types/auth";
import { db } from "@/configs/firebase";
import {
	getAuth,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signInWithCredential,
	sendPasswordResetEmail,
} from "firebase/auth";
import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";
import { SUM1_DEFAULT_ABOUT } from "@/constants/app.constant";

const auth = getAuth();

const functions = getFunctions();

const sendEmail = httpsCallable(functions, "sendEmailWithTemplate");
const CUSTOM_ID = "custom123"

export async function apiSignIn(
	data: SignInCredential
): Promise<SignInResponse> {
	const signIn = await signInWithEmailAndPassword(
		auth,
		data.userName,
		data.password
	);

	if (signIn.user) {
		const userDoc = await getDoc(doc(db, "users", signIn.user.uid));

		await updateDoc(doc(db, "users", signIn.user.uid), {
			lastLogin: Date.now(),
		});

		return {
			message: "",
			token: (signIn as any)._tokenResponse?.idToken,
			user: {
				firstName: userDoc.data()?.firstName,
				lastName: userDoc.data()?.lastName,
				userName:
					userDoc.data()?.firstName + " " + userDoc.data()?.lastName,
				email: userDoc.data()?.email,
				phone: userDoc.data()?.phone,
				authority: userDoc.data()?.authority,
				avatar: userDoc.data()?.avatar,
				userId: userDoc.data()?.userId,
				boarded: userDoc.data()?.boarded,
				firstLogin: userDoc.data()?.firstLogin,
				ficaSubmitted: userDoc.data()?.ficaSubmitted,
				signUpMethod: userDoc.data()?.signUpMethod,
				emailVerified: userDoc.data()?.emailVerified,
				verified: userDoc.data()?.verified,
				phoneVerified: userDoc.data()?.phoneVerified,
				beneficiariesSet: userDoc.data()?.beneficiariesSet,
				profile: userDoc.data()?.profile,
				fica: userDoc.data()?.fica,
			},
			status: "success",
		};
	}
	return {
		message: "Invalid username or password",
		token: "",
		user: {
			firstName: "",
			lastName: "",
			userName: "",
			email: "",
			phone: "",
			authority: [],
			avatar: "",
			userId: "",
			boarded: false,
			ficaSubmitted: false,
			signUpMethod: "",
			emailVerified: false,
			verified: false,
			phoneVerified: false,
			beneficiariesSet: false,
			firstLogin: true,
			profile: {
				location: "",
				age: "",
				gender: "",
				idNumber: "",
				occupationSourceOfIncome: "",
				highestLevelOfEducation: "",
				currentlyInvolvedInOtherStokvels: "",
				financialAdvicerNeed: "",
				accessToFinancialEducationalTools: "",
				passportNumber: "",
			},
			fica: {
				documentType: "",
				passportCover: { url: "", name: "", type: "" },
				passportDataPage: { url: "", name: "", type: "" },
				nationalIdFront: { url: "", name: "", type: "" },
				nationalIdBack: { url: "", name: "", type: "" },
				proofOfAddress: { url: "", name: "", type: "" },
			},
		},
		status: "failed",
	};
}

export async function getUser(userid: string) {
	const userDoc = await getDoc(doc(db, "users", userid));
	if (userDoc) {
		return {
			message: "",
			user: {
				firstName: userDoc.data()?.firstName,
				lastName: userDoc.data()?.lastName,
				userName:
					userDoc.data()?.firstName + " " + userDoc.data()?.lastName,
				email: userDoc.data()?.email,
				phone: userDoc.data()?.phone,
				authority: userDoc.data()?.authority,
				avatar: userDoc.data()?.avatar,
				userId: userDoc.data()?.userId,
				boarded: userDoc.data()?.boarded,
				firstLogin: userDoc.data()?.firstLogin,
				ficaSubmitted: userDoc.data()?.ficaSubmitted,
				signUpMethod: userDoc.data()?.signUpMethod,
				emailVerified: userDoc.data()?.emailVerified,
				verified: userDoc.data()?.verified,
				phoneVerified: userDoc.data()?.phoneVerified,
				beneficiariesSet: userDoc.data()?.beneficiariesSet,
				profile: userDoc.data()?.profile,
				fica: userDoc.data()?.fica,
			},
			status: "success",
		};
	}
}

export async function apiFetchSum1About(): Promise<any> {
	const about = await getDoc(doc(db, "product", CUSTOM_ID))
 return about.data()?.aboutText;
}

export async function newDocument(): Promise<boolean> {
  
	// Ensure CUSTOM_ID is valid
	if (!CUSTOM_ID) {
	  return false;
	}
  
	try {
	  await setDoc(doc(db, "product", CUSTOM_ID), {
		aboutText: SUM1_DEFAULT_ABOUT,
	  });
	  return true;
	} catch (error) {
	  return false;
	}
  }

  export async function setSum1About(newAbout: string): Promise<void> {
    try {
        const aboutRef = doc(db, "product", CUSTOM_ID); 
        const aboutSnap = await getDoc(aboutRef);

        if (!aboutSnap.exists()) {
            console.error("Document not found!");
            return;
        }

        await updateDoc(aboutRef, { aboutText: newAbout }); 

    } catch (error) {
		SendNotification('Something went wrong', "info")
    }
}


export async function apiSignUpEmail(
	data: SignUpEmailCredential
): Promise<SignUpResponse> {
	const code = Math.floor(100000 + Math.random() * 900000);

	// use firebase auth sign up with email and password
	const signUp = await createUserWithEmailAndPassword(
		auth,
		data.email,
		data.password
	);

	if (signUp.user) {
		await setDoc(doc(db, "users", signUp.user.uid), {
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			authority: ["USER"],
			avatar: "",
			userId: signUp.user.uid,
			created: Date.now(),
			updated: Date.now(),
			lastLogin: Date.now(),
			firstLogin: true,
			boarded: false,
			signUpMethod: "EMAIL",
			verificationCode: code,
			emailVerified: false,
			verified: false,
			phoneVerified: false,
			beneficiariesSet: false,
			phone: data.phone || "",
		});

		// send an email verification
		await sendEmail({
			to: data.email,
			template: "email-verification-code",
			subject: "Please Verify Your Email Address for Sum1 Investments",
			variables: {
				code,
				firstName: data.firstName,
				supportEmail: import.meta.env.VITE_REACT_APP_SUPPORT_EMAIL,
			},
		});

		// send an email to the support email
		await sendEmail({
			to: import.meta.env.VITE_REACT_APP_CLIENTS_EMAIL,
			template: "new-user-created",
			subject: "New User Sign Up",
			variables: {
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email || "None",
				phone: data.phone || "None",
				registeredAt: moment(new Date()).format("LLLL"),
			},
		});

		return {
			status: "success",
			message: "",
			userId: signUp.user.uid,
		};
	}
	return {
		status: "failed",
		message: "",
		userId: "",
	};
}

export async function apiSignUpPhone(
	data: SignUpPhoneCredential
): Promise<SignUpResponse> {
	const code = Math.floor(100000 + Math.random() * 900000);

	// check if user exists
	const userRef = collection(db, "users");
	const q = query(userRef, where("phone", "==", data.phone));
	const querySnapshot = await getDocs(q);

	if (!querySnapshot.empty) {
		//@TODO: handle user already exists
		return {
			status: "failed",
			message: "User already exists",
			userId: "",
		};
	}

	const docRef = await addDoc(collection(db, "users"), {
		firstName: data.firstName,
		lastName: data.lastName,
		phone: data.phone,
		authority: ["USER"],
		avatar: "",
		created: Date.now(),
		updated: Date.now(),
		lastLogin: Date.now(),
		firstLogin: true,
		boarded: false,
		verificationCode: code,
		signUpMethod: "PHONE",
		phoneVerified: false,
		verified: false,
	});

	// add the userId to the database
	const currentUserRef = doc(db, "users", docRef.id);
	await updateDoc(currentUserRef, {
		userId: docRef.id,
	});

	const sendSMS = httpsCallable(functions, "sendSMS");

	await sendEmail({
		to: import.meta.env.VITE_REACT_APP_CLIENTS_EMAIL,
		template: "new-user-created",
		subject: "New User Sign Up",
		variables: {
			firstName: data.firstName,
			lastName: data.lastName,
			email: "None",
			phone: data.phone || "None",
			registeredAt: moment(new Date()).format("LLLL"),
		},
	});

	let resp = {
		status: "",
		message: "",
		userId: "",
	};

	await sendSMS({
		phone: data.phone,
		message: `Hey ${data.firstName}, welcome to Sum 1 Investments. Your verification code is ${code}`,
	})
		.then((result) => {
			resp = {
				status: "success",
				message: "Verification code sent successfully",
				userId: docRef.id,
			};
		})
		.catch((error) => {
			console.log(error);
			resp = {
				status: "failed",
				message: "Verification code not sent",
				userId: "",
			};
		});

	return resp;
}
export async function apiVerifyCode(data: VerifyCode): Promise<SignInResponse> {
	const { code, userId } = data;

	const userRef = doc(db, "users", userId);
	const userDoc = await getDoc(userRef);

	const signUpMethod = userDoc.data()?.signUpMethod;

	if (userDoc.data()?.verificationCode.toString() === code) {
		if (signUpMethod === "EMAIL") {
			await updateDoc(userRef, {
				verified: true,
				emailVerified: true,
			});

			// redirect to sign in
			return {
				status: "success",
				message: "Verification successful",
				token: "",
				user: {
					firstName: userDoc.data()?.firstName,
					lastName: userDoc.data()?.lastName,
					userName:
						userDoc.data()?.firstName +
						" " +
						userDoc.data()?.lastName,
					email: userDoc.data()?.email,
					phone: userDoc.data()?.phone,
					authority: userDoc.data()?.authority,
					avatar: userDoc.data()?.avatar,
					userId: userDoc.data()?.userId,
					boarded: userDoc.data()?.boarded,
					ficaSubmitted: userDoc.data()?.ficaSubmitted,
					signUpMethod: userDoc.data()?.signUpMethod,
					emailVerified: userDoc.data()?.emailVerified,
					firstLogin: userDoc.data()?.firstLogin,
					verified: userDoc.data()?.verified,
					phoneVerified: userDoc.data()?.phoneVerified,
					beneficiariesSet: userDoc.data()?.beneficiariesSet,
					profile: userDoc.data()?.profile,
					fica: userDoc.data()?.fica,
				},
			};
		}

		if (signUpMethod === "PHONE") {
			await updateDoc(userRef, {
				verified: true,
				phoneVerified: true,
			});

			// get the user credential
			const generateLoginToken = httpsCallable(
				functions,
				"generateLoginToken"
			);

			const response = await generateLoginToken({ userId });

			if (response.data) {
				return {
					status: "success",
					message: "Verification successful",
					token: response.data as string,
					user: {
						firstName: userDoc.data()?.firstName,
						lastName: userDoc.data()?.lastName,
						userName:
							userDoc.data()?.firstName +
							" " +
							userDoc.data()?.lastName,
						email: userDoc.data()?.email,
						phone: userDoc.data()?.phone,
						authority: userDoc.data()?.authority,
						avatar: userDoc.data()?.avatar,
						userId: userDoc.data()?.userId,
						boarded: userDoc.data()?.boarded,
						ficaSubmitted: userDoc.data()?.ficaSubmitted,
						signUpMethod: userDoc.data()?.signUpMethod,
						emailVerified: userDoc.data()?.emailVerified,
						firstLogin: userDoc.data()?.firstLogin,
						verified: userDoc.data()?.verified,
						phoneVerified: userDoc.data()?.phoneVerified,
						beneficiariesSet: userDoc.data()?.beneficiariesSet,
						profile: userDoc.data()?.profile,
						fica: userDoc.data()?.fica,
					},
				};
			}
		}

		return {
			status: "failed",
			message: "Verification failed",
			token: "",
			user: {
				firstName: "",
				lastName: "",
				userName: "",
				email: "",
				phone: "",
				authority: [],
				avatar: "",
				userId: "",
				boarded: false,
				ficaSubmitted: false,
				signUpMethod: "",
				emailVerified: false,
				verified: false,
				phoneVerified: false,
				beneficiariesSet: false,		
				firstLogin: true,
				profile: {
					idNumber: "",
					age: "",
					gender: "",
					location: "",
					occupationSourceOfIncome: "",
					highestLevelOfEducation: "",
					currentlyInvolvedInOtherStokvels: "",
					financialAdvicerNeed: "",
					accessToFinancialEducationalTools: "",
					passportNumber: "",
				},
				fica: {
					documentType: "",
					passportCover: { url: "", name: "", type: "" },
					passportDataPage: { url: "", name: "", type: "" },
					nationalIdFront: { url: "", name: "", type: "" },
					nationalIdBack: { url: "", name: "", type: "" },
					proofOfAddress: { url: "", name: "", type: "" },
				},
			},
		};
	} else {
		return {
			status: "failed",
			message: "Invalid verification code, please try again",
			token: "",
			user: {
				firstName: "",
				lastName: "",
				userName: "",
				email: "",
				phone: "",
				authority: [],
				avatar: "",
				userId: "",
				boarded: false,
				ficaSubmitted: false,
				signUpMethod: "",
				emailVerified: false,
				verified: false,
				phoneVerified: false,
				beneficiariesSet: false,		
				firstLogin: true,
				profile: {
					idNumber: "",
					age: "",
					gender: "",
					location: "",
					occupationSourceOfIncome: "",
					highestLevelOfEducation: "",
					currentlyInvolvedInOtherStokvels: "",
					financialAdvicerNeed: "",
					accessToFinancialEducationalTools: "",
					passportNumber: "",
				},
				fica: {
					documentType: "",
					passportCover: { url: "", name: "", type: "" },
					passportDataPage: { url: "", name: "", type: "" },
					nationalIdFront: { url: "", name: "", type: "" },
					nationalIdBack: { url: "", name: "", type: "" },
					proofOfAddress: { url: "", name: "", type: "" },
				},
			},
		};
	}
}

export async function apiSignOut() {
	await auth.signOut();
}

export async function apiForgotPassword(data: ForgotPassword) {
	await sendPasswordResetEmail(auth, data.email);
}

export async function apiResetPassword(data: ResetPassword) {
	return ApiService.fetchData({
		url: "/reset-password",
		method: "post",
		data,
	});
}

export async function apiResendOTP(data: ResendOTP) {
	const code = Math.floor(100000 + Math.random() * 900000);

	const { userId } = data;

	if (!userId) {
		return {
			status: "failed",
			message: "User not found",
		};
	}

	const userRef = doc(db, "users", userId);
	const userDoc = await getDoc(userRef);

	if (!userDoc.exists()) {
		return {
			status: "failed",
			message: "User not found",
		};
	}

	await updateDoc(userRef, {
		verificationCode: code,
	});

	const sendSMS = httpsCallable(functions, "sendSMS");

	await sendSMS({
		phone: userDoc.data()?.phone,
		message: `Your verification code is ${code}`,
	})
		.then((result) => {
			console.log(result);
			return {
				status: "success",
				message: "Verification code sent successfully",
			};
		})
		.catch((error) => {
			console.log(error);
			return {
				status: "failed",
				message: "Verification code not sent",
			};
		});
}

export async function apiSendOTP(phone: string) {
	const code = Math.floor(100000 + Math.random() * 900000);

	// check if a user with the phone number exists
	const userRef = collection(db, "users");
	const q = query(userRef, where("phone", "==", phone));
	const querySnapshot = await getDocs(q);

	if (querySnapshot.empty) {
		return {
			status: "failed",
			message: "User not found",
			userId: "",
		};
	}

	const userDoc = querySnapshot.docs[0];
	const userId = userDoc.id;

	await updateDoc(doc(db, "users", userId), {
		verificationCode: code,
	});

	const sendSMS = httpsCallable(functions, "sendSMS");

	await sendSMS({
		phone,
		message: `Your verification code is ${code}`,
	}).catch((error) => {
		console.log(error);
		return {
			status: "failed",
			message: "Verification code not sent",
			userId: "",
		};
	});

	return {
		status: "success",
		message: "Verification code sent successfully",
		userId,
	};
}
