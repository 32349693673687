import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE_BASE_NAME } from "./constants";

export type UserState = {
	avatar?: string;
	userName?: string;
	email?: string;
	authority?: string[];
	firstName?: string;
	userId?: string;
	lastName?: string;
	boarded?: boolean;
	phone?: string;
	ficaSubmitted?: boolean;
	emailVerified?: boolean;
	verified?: boolean;
	phoneVerified?: boolean;
	beneficiariesSet?: boolean;
	notificationCount?: number;
	firstLogin?: boolean;
	profile?: {
		idNumber?: string;
		age?: string;
		gender?: string;
		location?: string;
		occupationSourceOfIncome?: string;
		highestLevelOfEducation?: string;
		currentlyInvolvedInOtherStokvels?: string;
		financialAdvicerNeed?: string;
		accessToFinancialEducationTools?: string;
		passportNumber?: string;
	};
	fica?: {
		documentType?: string;
		passportCover?: { url: string; name: string; type: string };
		passportDataPage?: { url: string; name: string; type: string };
		nationalIdFront?: { url: string; name: string; type: string };
		nationalIdBack?: { url: string; name: string; type: string };
		proofOfAddress?: { url: string; name: string; type: string };
	};
};

const initialState: UserState = {
	avatar: "",
	userName: "",
	email: "",
	authority: [],
	firstName: "",
	lastName: "",
	userId: "",
	boarded: false,
	phone: "",
	ficaSubmitted: false,
	emailVerified: false,
	verified: false,
	phoneVerified: false,
	beneficiariesSet: false,
	notificationCount: 0,
	profile: {
		idNumber: "",
		age: "",
		gender: "",
		location: "",
		occupationSourceOfIncome: "",
		highestLevelOfEducation: "",
		currentlyInvolvedInOtherStokvels: "",
		financialAdvicerNeed: "",
		accessToFinancialEducationTools: "",
		passportNumber: "",
	},
	fica: {
		documentType: "",
		passportCover: { url: "", name: "", type: "" },
		passportDataPage: { url: "", name: "", type: "" },
		nationalIdFront: { url: "", name: "", type: "" },
		nationalIdBack: { url: "", name: "", type: "" },
		proofOfAddress: { url: "", name: "", type: "" },
	},
};

const userSlice = createSlice({
	name: `${SLICE_BASE_NAME}/user`,
	initialState,
	reducers: {
		setUser(state, action: PayloadAction<UserState>) {
			state.avatar = action.payload?.avatar;
			state.email = action.payload?.email;
			state.userName = action.payload?.userName;
			state.authority = action.payload?.authority;
			state.firstName = action.payload?.firstName;
			state.userId = action.payload.userId;
			state.lastName = action.payload?.lastName;
			state.boarded = action.payload?.boarded;
			state.phone = action.payload?.phone;
			state.ficaSubmitted = action.payload?.ficaSubmitted;
			state.emailVerified = action.payload?.emailVerified;
			state.verified = action.payload?.verified;
			state.firstLogin = action.payload.firstLogin;
			state.phoneVerified = action.payload?.phoneVerified;
			state.beneficiariesSet = action.payload?.beneficiariesSet;
			state.profile = action.payload?.profile;
			state.fica = action.payload?.fica;
		},
		setNotificationCount(state, action: PayloadAction<number>) {
			state.notificationCount = action.payload;
		},
	},
});

export const { setUser, setNotificationCount } = userSlice.actions;
export default userSlice.reducer;
