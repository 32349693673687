import React from "react";
import toast from "../ui/toast";
import Notification from "../ui/Notification"; // Import the missing Notification component
import { TypeAttributes } from "../ui/@types/common";

const SendNotification = (
	message: string,
	type: TypeAttributes.Status,
	title?: string
) => {
	return toast.push(
		<Notification type={type} duration={5000} title={title}>
			{message}
		</Notification>,
		{
			placement: "bottom-center",
		}
	);
};

export default SendNotification;
