import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export type CommonState = {
    currentRouteKey: string
    aboutText: string,
}

export const initialState: CommonState = {
    currentRouteKey: '',
    aboutText: '',
}

export const commonSlice = createSlice({
    name: `${SLICE_BASE_NAME}/common`,
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action: PayloadAction<string>) => {
            state.currentRouteKey = action.payload
        },
        setAboutText: (state, action: PayloadAction<string>) => {
            state.aboutText = action.payload
        }
    },
})

export const { setCurrentRouteKey, setAboutText } = commonSlice.actions

export default commonSlice.reducer
